import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

@Injectable({ providedIn: 'root' })
export class ComplianceDetailsConfig {
  breadcrumbs: BreadcrumbItem[] = [
    { label: 'Compliance', url: '/compliance/register' },
    { label: '', url: '' },
  ];

  complianceTabs: DetailsTab[] = [
    {
      link: '/compliance/register/:id/overview',
      icon: 'overview-tab',
      label: 'Overview',
      isActive: true,
    },
    {
      link: '/compliance/register/:id/pdf-preview',
      icon: 'pdf-view-tab',
      label: 'PDF Preview',
      isActive: false,
    },
    {
      link: '/compliance/register/:id/associated-items',
      icon: 'associated-items-tab',
      label: 'Associated Items',
      isActive: false,
    },
    {
      link: '/compliance/register/:id/requirements', 
      icon: 'requirements-tab',
      label: 'Compliance Requirements',
      isActive: false,
    },
    {
      link: '/compliance/register/:id/action-plans',
      icon: 'action-plans-tab',
      label: 'Action Plans',
      isActive: false,
    },
  ];
}
